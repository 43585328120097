import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// Import CSS specific to Footer
import './Dashboard.css';

const Dashboard = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { hostname, port } = window.location;
  // Define the URL based on the current hostname and port
  const vdc = (hostname === '183.82.10.144' && port === '40407')
    ? 'http://183.82.10.144:40407/vdc/'
    : 'http://10.0.4.112:40407/vdc/';

  const ot = (hostname === '183.82.10.144' && port === '40407')
    ? 'http://183.82.10.144:40407/ot/index2.php'
    : 'http://10.0.4.112:40407/ot/index2.php';

  const app = (hostname === '183.82.10.144' && port === '40407')
    ? 'http://183.82.10.144:40407/appointments'
    : 'http://10.0.4.112:40407/appointments';

  const mtn = (hostname === '183.82.10.144' && port === '40407')
    ? 'http://183.82.10.144:40407/mtnceticketing/'
    : 'http://10.0.4.112:40407/mtnceticketing/';

  const dmg = (hostname === '183.82.10.144' && port === '40407')
    ? 'http://183.82.10.144:40407/dmg/'
    : 'http://10.0.4.112:40407/dmg/';

  const randr = (hostname === '183.82.10.144' && port === '40407')
    ? 'http://183.82.10.144:40407/randr/'
    : 'http://10.0.4.112:40407/randr/';

  const bill = (hostname === '183.82.10.144' && port === '40407')
    ? 'http://183.82.10.144:40407/bill_reminder/login'
    : 'http://10.0.4.112:40407/bill_reminder/login';

  const uniform = (hostname === '183.82.10.144' && port === '40407')
    ? 'http://183.82.10.144:40407/uniform'
    : 'http://10.0.4.112:40407/uniform';

  const osb = (hostname === '183.82.10.144' && port === '40407')
    ? 'http://183.82.10.144:40407/osb'
    : 'http://10.0.4.112:40407/osb';

  useEffect(() => {
    const fetchItems = async () => {
      try {

        const response = await fetch('https://central.vdcapp.in/admin/api/api/announcements', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        console.log(result.data);
        if (result.status) {
          setItems(result.data);
        } else {
          setError(result.message);
        }
      } catch (error) {
        console.error('Fetch error:', error);
        setError('Failed to fetch items');
      } finally {
        setLoading(false);
      }
    };

    fetchItems();
  }, [navigate]);

  return (

    <div>
      <header id="header" className="header sticky-top">
        <div className="topbar d-flex align-items-center">
          <div className="container d-flex justify-content-center justify-content-md-between">
            <div className="d-none d-md-flex align-items-center">
              <a href="/" className="logo d-flex align-items-center me-auto">
                <img src="assets/img/logo1.png" alt="" />
              </a>
            </div>
            <div className="d-flex align-items-center">
              <i className="bi bi-phone me-1"></i> Call Center Number +91 9240 222 222
            </div>
          </div>
        </div>

        <br />
        <center>
          <h3 className="shadow-text" style={{ color: '#C78284' }}>
            VIJAYA DIAGNOSTIC CENTRALIZED DASHBOARD
          </h3>
        </center>
      </header>

      <br />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-3">
                <div className="icon-title-description">
                  <a href="https://www.vijayadiagnostic.com/" target="new1" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                    <div className="icon">
                      <img src="assets/img/vdc.png" alt="Vijaya" />
                    </div>
                    <div className="title">
                      <h2>Vijaya</h2>
                    </div>
                    <div className="description">
                      <p>Official Website</p>
                    </div>
                  </a>
                </div>
              </div>

              <div className="col-md-3">
                <a href="https://vlims.vijayadiagnostic.com/#/laboratory/account/login" target="new2" style={{ textDecoration: 'none' }}>
                  <div className="icon-title-description">
                    <div className="icon">
                      <img src="assets/img/sufalam.png" alt="Sufalam Icon" />
                    </div>
                    <div className="title">
                      <h3>Sufalam</h3>
                    </div>
                    <div className="description">
                      <p>LIMS</p>
                    </div>
                  </div>
                </a>
              </div>

              {/* Repeat similar blocks for other icons */}
              {/* ... */}

              <div className="col-md-3">
                <a href="https://augmento.vijayadiagnostic.com/login" target="new3" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                  <div className="icon-title-description">
                    <br />
                    <div className="icon">
                      <img src="assets/img/augment.png" style={{ width: '50%' }} alt="Augmento" />
                    </div>
                    <div className="title">
                      <h2>Augmento</h2>
                    </div>
                    <div className="description">
                      <p>PACS</p>
                    </div>
                  </div>
                </a>
              </div>

              <div className="col-md-3">
                <a href="https://vmitr.myadrenalin.com/AdrenalinMax/#/" target="new4" style={{ textDecoration: 'none' }}>
                  <div className="icon-title-description">
                    <div className="icon">
                      <img src="assets/img/adrenalin_icon.png" alt="Adrenaline Icon" />
                    </div>
                    <div className="title">
                      <h3>Adrenalin</h3>
                    </div>
                    <div className="description">
                      <p>HRMS</p>
                    </div>
                  </div>
                </a>
              </div>


              {/* ... add other blocks as needed */}

              <div className="row mt-4">
                <div className="col-md-3">
                  <a href="https://verp.vijayadiagnostic.com/focusx" target="new5" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                    <div className="icon-title-description">
                      <br />
                      <div className="icon">
                        <img style={{ width: '42%' }} src="assets/img/focus_logo.png" alt="Focus" />
                      </div>
                      <div className="title">
                        <h2>Focus</h2>
                      </div>
                      <div className="description">
                        <p>ERP</p>
                      </div>
                    </div>
                  </a>
                </div>

                <div className="col-md-3">
                    <a href="https://outlook.office.com/" target="new15" style={{ textDecoration: 'none' }}>
                      <div className="icon-title-description">
                      <br />
                        <div className="icon">
                          <img style={{ width: '15%' }} src="assets/img/outlook_logo.png" alt="Focus" />
                        </div>
                        <div className="title">
                          <h2>Outlook</h2>
                        </div>
                        <div className="description">
                          <p>Outlook Mail</p>
                        </div>
                      </div>
                    </a>
                  </div>

                <div className="col-md-3">
                  <a href={vdc} target="new7" style={{ textDecoration: 'none' }}>
                    <div className="icon-title-description">

                      <div className="icon">
                        <img style={{ width: '25%' }} src="assets/img/branch_info_icon.png" alt="VDC APP" />
                      </div>
                      <div className="title">
                        <h3>VDC APP</h3>
                      </div>
                      <div className="description">
                        <p>Branch Information</p>
                      </div>
                    </div>
                  </a>
                </div>



                {/* Repeat similar blocks for other icons */}
                {/* ... */}

                <div className="col-md-3">
                  <a href="https://partner.vijayadiagnostic.com/#/" target="new6" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                    <div className="icon-title-description">
                      <br />
                      <div className="icon">
                        <img style={{ width: '17%' }} src="assets/img/partner_portal.png" alt="Partner Portal" />
                      </div>
                      <div className="title">
                        <h2>Partner Portal</h2>
                      </div>
                      <div className="description">
                        <p>Home Collection</p>
                      </div>
                    </div>
                  </a>
                </div>

                {/* Add other blocks as needed */}
                



              </div>



              <div className="row mt-4">

              <div className="col-md-3">
                  <a href={app} target="new9" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                    <div className="icon-title-description">
                      
                      <div className="icon">
                        <center>
                          <img style={{ width: '50%' }} src="assets/img/appoin.jpg" alt="Appointments" />
                        </center>
                      </div>
                      <div className="title">
                        <h2>Appointments</h2>
                      </div>
                      <div className="description">
                        <p style={{ fontSize: '12px' }}>MRI,ULTRASOUND,NM,PETCT</p>
                      </div>
                    </div>
                  </a>
                </div>

                <div className="col-md-3">
                  <a href={bill} target="new13" style={{ textDecoration: 'none' }}>
                    <div className="icon-title-description">
                    <br />
                      <div className="icon">
                        <img style={{ width: '20%' }} src="assets/img/remainder.jpg" alt="Augmento" />
                      </div>
                      <div className="title">
                        <h2>Reminder</h2>
                      </div>
                      <div className="description">
                        <p>Bill Reminder</p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-md-3">
                  <a href={mtn} target="new10" style={{ textDecoration: 'none' }}>
                    <div className="icon-title-description">
                    <br />
                      <div className="icon">

                        <center ><img style={{ width: '20%' }} src="assets/img/ticket.png" alt="Card image 1" /></center>



                      </div>

                      <div className="title">
                        <h2>Maintenance</h2>
                      </div>
                      <div className="description">
                        <p>Ticketing System</p>
                      </div>
                    </div>
                  </a>
                </div>

                <div className="col-md-3">
                  <a href={dmg} target="new11" style={{ textDecoration: 'none' }}>
                    <div className="icon-title-description">
                    <br />
                      <div className="icon">

                        <center ><img style={{ width: '20%' }} src="assets/img/direct.jpg" alt="Card image 1" /></center>



                      </div>
                      <div className="title">
                        <h2>DMG</h2>
                      </div>
                      <div className="description">
                        <p>Direct Marketing</p>
                      </div>
                    </div>
                  </a>
                </div>
                


                {/* Add more blocks as needed */}
                <div className="row mt-4">

                <div className="col-md-3">
                  <a href="https://doctor.vdcapp.in/admin/login.php" target="new14" style={{ textDecoration: 'none' }}>
                    <div className="icon-title-description">
                      <div className="icon">
                        <img style={{ width: '13%' }} src="assets/img/doctor_feed.jpg" alt="Focus" />
                      </div>
                      <div className="title">
                        <h2>Doctor</h2>
                      </div>
                      <div className="description">
                        <p>Feedback</p>
                      </div>
                    </div>
                  </a>
                </div>
                  <div className="col-md-3">
                    <a href={uniform} target="new14" style={{ textDecoration: 'none' }}>
                      <div className="icon-title-description">
                        <div className="icon">
                          <img style={{ width: '16%' }} src="assets/img/uniform.jpg" alt="Focus" />
                        </div>
                        <div className="title">
                          <h2>Uniform</h2>
                        </div>
                        <div className="description">
                          <p>Uniform System</p>
                        </div>
                      </div>
                    </a>
                  </div>

                  <div className="col-md-3">
                    <a href={randr} target="new12" style={{ textDecoration: 'none' }}>
                      <div className="icon-title-description">
                        <div className="icon">

                          <center ><img style={{ width: '21%' }} src="assets/img/rewards.png" alt="Card image 1" /></center>


                        </div>
                        <div className="title">
                          <h2><center>R&R</center></h2>
                        </div>
                        <div className="description">
                          <p>Rewards & Recognition</p>
                        </div>
                      </div>
                    </a>
                  </div>

                  <div className="col-md-3">
                    <a href={ot} target="new8" style={{ textDecoration: 'none' }}>
                      <div className="icon-title-description">

                        <div className="icon">

                          <img style={{ width: '16%' }} src="assets/img/overtime_icon.png" alt="VDC OT Icon" />

                        </div>
                        <div className="title">
                          <h2>VDC OT</h2>
                        </div>
                        <div className="description">
                          <p>Overtime</p>
                        </div>
                      </div>
                    </a>
                  </div>
                  </div>
                  <div className="row mt-4">
                  <div className="col-md-3">
                    <a href="https://osb.vdcapp.in/" target="new14" style={{ textDecoration: 'none' }}>
                      <div className="icon-title-description">
                        <div className="icon">
                          <img style={{ width: '15%' }} src="assets/img/osb.png" alt="Focus" />
                        </div>
                        <div className="title">
                          <h2>OSB</h2>
                        </div>
                        <div className="description">
                          <p>Outsourcing Biometric</p>
                        </div>
                      </div>
                    </a>
                  </div>
                 
                  <div className="col-md-3">
                    <a href="https://travelapp.vdcapp.in" target="new14" style={{ textDecoration: 'none' }}>
                      <div className="icon-title-description">
                        <div className="icon">
                          <img style={{ width: '15%' }} src="assets/img/travel_icon.png" alt="Focus" />
                        </div>
                        <div className="title">
                          <h2>Travel App</h2>
                        </div>
                        <div className="description">
                          <p>Tour And Travel App</p>
                        </div>
                      </div>
                    </a>
                  </div>



                  {/* Add more blocks as needed */}
                </div>
                
                {/* <div className="row mt-4">
                <div className="col-md-3">
                    <a href="https://outlook.office.com/" target="new15" style={{ textDecoration: 'none' }}>
                      <div className="icon-title-description">
                        <div className="icon">
                          <img style={{ width: '15%' }} src="assets/img/outlook_logo.png" alt="Focus" />
                        </div>
                        <div className="title">
                          <h2>Outlook</h2>
                        </div>
                        <div className="description">
                          <p>Outlook Mail</p>
                        </div>
                      </div>
                    </a>
                  </div>
                  
                </div> */}
              </div>
              
            </div>
          </div>

          <div className="col-md-3">
            <div className="announcements-notifications">

              <b><center>Announcements</center></b>
              <br />
              <marquee width="100%" direction="up" height="470px" behavior="scroll" scrollamount="3">
                Welcome to Our Website! 🌟 Stay Updated with the Latest News and Offers. 📢 Check Back Often for More Updates!
                {loading && <p>Loading...</p>}
                {error && <p className="error">{error}</p>}
                {!loading && !error && items.length === 0 && <p>No announcements available.</p>}
                {!loading && !error && items.length > 0 && items.map((item, index) => (
                  <div key={index} className="notification-item">
                    <h4>{item.title}</h4>
                    <p>{item.description}</p>
                  </div>
                ))}

              </marquee>

              {/* Add more announcements as needed */}
            </div>
          </div>



        </div>
      </div>
      <br/>
      <footer id="footer" className="footer light-background sticky-bottom">
        <div className="container text-center mt-4">
          <p>© <span>Copyright</span> <strong className="px-1 sitename">2024 by Vijaya Diagnostic Centre Limited</strong> <span>All Rights Reserved</span></p>
          <div className="credits">
            {/* Add credits or additional footer content if needed */}
          </div>
        </div>
      </footer>
    </div>

  );
};

export default Dashboard;